import React from "react";
import Section1 from "../components/section_1";
import Section3 from "../components/section_3";
import Section5 from "../components/section_5";
import Section6 from "../components/section_6";
import Section7 from "../components/section_7";
import Accordion from "../components/accordion";
import Subscribe from "../components/subscribe _section";
import Footer from "../components/footer";
import Logos from "../components/companyLogo";
import Tabs from "../components/tabsAnimation";
// import Popup from "../components/popup";
import OtherWeb from "../components/otherWeb";
// import Freepopup from "../components/freepopup";
// import FreeContent from "../components/freecontent";

const MainRoute = () => {
  // const [filled, setFilled] = useState(false);

  return (
    <main>
      {/* <Popup setFilled={setFilled} /> */}
      {/* {filled && <Freepopup />} */}
      <Section1 />
      {/* {filled && <FreeContent />} */}
      <Logos />
      <Section3 />
      <Tabs />
      <Section5 />
      <Section6 />
      <Section7 />
      <Accordion />
      <Subscribe />
      <OtherWeb />
      <Footer />
    </main>
  );
};

export default MainRoute;
